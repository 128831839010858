import React, { useState } from "react";
import "./AppSection.scss";
import { Carousel } from "flowbite-react";
import playstore from "../../assets/images/google-play.png";
import appstore from "../../assets/images/apple.png";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import Button from "../../commonComponents/Button/Button";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { useDispatch, useSelector } from "react-redux";
import { subscribeNewsLetter } from "../../redux/actionCreator";
import { subscribe_loading } from "../../redux/commonReducer";
import { toast } from "react-toastify";

const AppSection = ({ data, disableNewsLetter, appData, appRef }) => {
  const dispatch = useDispatch();

  const loading = useSelector(subscribe_loading);

  const [email, setEmail] = useState("");
  const [err, setErr] = useState({});

  const validate = () => {
    let temp = {};
    if (!email?.trim()?.length) {
      temp = {
        ...temp,
        email: "Please enter email",
      };
    }else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(email?.trim())) {
        temp = {
          ...temp,
          email: "Please enter valid email",
        };
      }
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      let raw = {
        email: email?.trim(),
      };
      dispatch(
        subscribeNewsLetter(raw, (res) => {
          setEmail("");
        })
      );
    } else {
      toast.error(err?.email, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div
      className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 app__section overflow-x-hidden"
      ref={appRef}
    >
      <div className="grid grid-cols-12 gap-6 w-full h-full mt-6">
        <div
          data-aos="fade-left"
          className={`xs:col-span-12 md:col-span-6 w-full details__container`}
        >
          <h4 className="heading">{data?.app_title}</h4>
          <h4>{data?.app_desc}</h4>
          <div className="grid grid-cols-12 gap-6 w-full mt-6">
            <a
              href={data?.android_link}
              target="_blank"
              rel="noreferrer"
              className="md:col-span-4 xs:col-span-6 w-full app__button"
            >
              <img src={playstore} alt="" />
              <div>
                <h4 className="text ff-medium">GET IT ON</h4>
                <h4 className="service__heading ff-semibold">Google Play</h4>
              </div>
            </a>
            <a
              href={data?.ios_link}
              target="_blank"
              rel="noreferrer"
              className={`md:col-span-4 xs:col-span-6 w-full app__button`}
            >
              <img src={appstore} alt="" />
              <div>
                <h4 className="text ff-medium">COMING SOON ON</h4>
                <h4 className="service__heading ff-semibold">APP STORE</h4>
              </div>
            </a>
          </div>
        </div>
        <div
          data-aos="fade-right"
          className={`xs:col-span-12 md:col-span-6 w-full xs:h-[400px] md:h-auto`}
        >
          <Carousel leftControl=" " rightControl=" ">
            {appData?.map((ele, i) => {
              return (
                <div className="app__slider__container">
                  <img
                    src={`${IMAGE_BASE_URL}${"home"}/${ele?.image}`}
                    alt={ele?.image_alt_text}
                    className="app__slider md:h-[400px] "
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      {disableNewsLetter ? (
        ""
      ) : (
        <div
          data-aos="zoom-in"
          className="updates__section md:mt-[80px] xs:mt-[30px] md:p-[10px] md:px-[70px] xs:px-[20px]"
        >
          <h4 className="update__heading md:text-2xl xs:text-base">
            {data?.cta_title}
          </h4>
          <h4 className="update__description md:text-xl xs:text-sm md:mt-[-20px] xs:mt-[-35px]">
            {data?.cta_sub_title}
          </h4>
          <div className="input__container">
            <input
              placeholder="Enter Your Email"
              className="md:w-[500px] xs:w-[230px] "
              onChange={(e) => setEmail(e?.target?.value)}
              value={email}
            />
            <Button
              icon={arrowupShort}
              iconClassName={"ml-1 signup__icon"}
              content="Submit"
              onClick={(e) => onSubmitHandler(e)}
              loader={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppSection;
