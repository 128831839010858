import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,
        is_business: false,
        apply_modal : false
    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state) => state.commonReducer.value;
export const is_business = (state) => state.commonReducer.is_business;
export const blog_lists = (state) => state.commonReducer.blog_lists;
export const latest_blogs = (state) => state.commonReducer.latest_blogs;
export const blog_details = (state) => state.commonReducer.blog_details;
export const add_getInTouch_loading = (state) => state.commonReducer.add_getInTouch_loading;
export const workflows = (state) => state.commonReducer.workflows;
export const workflowPath = (state) => state.commonReducer.workflowPath;

export const customerHomeData = (state) => state.commonReducer.customerHomeData;
export const customerHomePath = (state) => state.commonReducer.customerHomePath;
export const businessHomeData = (state) => state.commonReducer.businessHomeData;
export const businessHomePath = (state) => state.commonReducer.businessHomePath;

export const cms_data = (state) => state.commonReducer.cms_data;
export const cms_path = (state) => state.commonReducer.cms_path;

export const about_data = (state) => state.commonReducer.about_data;

export const discover_data = (state) => state.commonReducer.discover_data;

export const add_contact_us_loading = (state) => state.commonReducer.add_contact_us_loading;

export const apply_modal = (state) => state.commonReducer.apply_modal;

export const career_loading = (state) => state.commonReducer.career_loading;
export const blogs_total_count = (state) => state.commonReducer.blogs_total_count;
export const privay_policy = (state) => state.commonReducer.privay_policy;
export const legal_informations = (state) => state.commonReducer.legal_informations;
export const terms_and_conditions = (state) => state.commonReducer.terms_and_conditions;

export const subscribe_loading = (state) => state.commonReducer.subscribe_loading;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;
// export const value = (state) => state.commonReducer.value;

export default commonSlice.reducer;