import React, { useState } from "react";
import "./DiscoverSection.scss";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const DiscoverSection = ({ data, path }) => {
  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 discover__section md:mt-20">
      <h4 className="discover__title">DISCOVER</h4>
      <div className="discover__description xs:w-[100%] md:mb-12">
        <p data-aos="fade-up">{data?.details?.discover_desc}</p>
      </div>
      <div class="grid grid-cols-12 gap-4 w-full mt-6">
        {data?.discoverData?.map((item, index) => {
          let rowValue = 0;
          if (index) {
            rowValue = index / 2;
          }
          let gridValue =
            Math.floor(rowValue) % 2 ? (index % 2 ? 7 : 5) : index % 2 ? 5 : 7;
          return (
            <div
              data-aos={gridValue === 7 ? "fade-left" : "fade-right"}
              class={`xs:col-span-12 md:p-50
                ${
                  gridValue === 7 ? "md:col-span-7" : "md:col-span-5"
                }  w-full discover__grid xs:h-auto md:h-[380px]`}
            >
              <div className="details__section">
                <h4 className="main__heading mb-1 xs:text-sm md:text-base">
                  {item.title}
                </h4>
                <h2 className="heading mb-1 xs:text-sm md:text-base">
                  {item.sub_title}
                </h2>
                <p className="description xs:text-sm md:text-base">
                  {item.discover_desc}
                </p>
              </div>
              <img
                src={`${IMAGE_BASE_URL}${"home"}/${item?.image}`}
                className="xs:w-[150px] md:w-[200px] "
                alt={item?.discover_image_alt_text}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DiscoverSection;
