import AboutSection from "./AboutSection";
import AppSection from "./AppSection";
import DiscoverSection from "./DiscoverSection";
import MainSection from "./MainSection";
import HowItWorksSection from "./HowItWorksSection";
import PartnerSection from "./PartnerSection";
import PricingSection from "./PricingSection";
import ClientsSection from "./ClientsSection";
import Testimonials from "./Testimonials";
import { useDispatch, useSelector } from "react-redux";
import {
  businessHomeData,
  businessHomePath,
  customerHomeData,
  customerHomePath,
  is_business,
} from "../../redux/commonReducer";
import HomeBlogSection from "../Blogs/HomeBlogSection";
import { useEffect, useRef } from "react";
import { getBusinessHome, getCustomerHome } from "../../redux/actionCreator";

function HomePage() {
  const dispatch = useDispatch();

  const isBusiness = useSelector(is_business);
  const customerData = useSelector(customerHomeData);
  const customerPath = useSelector(customerHomePath);
  const businessData = useSelector(businessHomeData);
  const businessPath = useSelector(businessHomePath);

  const appRef = useRef(null);

  const executeScroll = () => appRef.current.scrollIntoView();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isBusiness) {
      dispatch(getBusinessHome());
    } else {
      dispatch(getCustomerHome());
    }
  }, [isBusiness]);

  return (
    <>
      {/* multiple */}
      <MainSection
        data={isBusiness ? businessData : customerData}
        path={isBusiness ? businessPath : customerPath}
        executeScroll={executeScroll}
      />
      <DiscoverSection
        data={isBusiness ? businessData : customerData}
        path={isBusiness ? businessPath : customerPath}
      />
      <HowItWorksSection data={isBusiness ? businessData : customerData} />
      <AboutSection data={isBusiness ? businessData : customerData} />
      {/* multiple */}
      {isBusiness ? <PartnerSection data={businessData} /> : ""}
      <HomeBlogSection data={isBusiness ? businessData : customerData} />
      {isBusiness ? <PricingSection data={businessData} /> : ""}
      {isBusiness ? "" : <ClientsSection data={customerData} />}
      {isBusiness ? "" : <Testimonials data={customerData} />}
      <AppSection
        appRef={appRef}
        data={isBusiness ? businessData?.details : customerData?.details}
        appData={isBusiness ? businessData?.appData : customerData?.appData}
      />
    </>
  );
}

export default HomePage;
