import React, { useEffect, useState } from "react";
import "./AboutSection.scss";
import Button from "../../commonComponents/Button/Button";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRedux } from "../../redux/commonReducer";

const AboutSection = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 about__section">
      <div class="grid grid-cols-12 gap-6 w-full mt-6 mb-6">
        {data?.teamMembers?.length ? (
          <div
            class={`xs:col-span-12 md:col-span-6 w-full grid grid-cols-12 gap-2`}
            data-aos="fade-left"
          >
            <div className="xs:col-span-6 md:col-span-5 w-full">
              <img
                src={`${IMAGE_BASE_URL}${"team"}/${
                  data?.teamMembers?.[0]?.image
                }`}
                className="about__profile1"
                alt={data?.teamMembers?.[0]?.image_alt_text}
              />
            </div>
            <div className="xs:col-span-6 md:col-span-5 w-full profile2__Section">
              <img
                src={`${IMAGE_BASE_URL}${"team"}/${
                  data?.teamMembers?.[1]?.image
                }`}
                className="about__profile2"
                alt={data?.teamMembers?.[1]?.image_alt_text}
              />
              <h4 className="join__text">interested to join our team?</h4>
              <h2
                className="apply__now__text cursor-pointer"
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "apply_modal",
                      value: true,
                    })
                  )
                }
              >
                Apply Now
              </h2>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div
          data-aos="fade-right"
          class={`xs:col-span-12 md:col-span-6 w-full`}
        >
          <h4 className="abount__us__title ">ABOUT US</h4>
          <h2 className="about__us_heading ">{data?.details?.about_title}</h2>
          <p className="about__us__description ">{data?.details?.about_desc}</p>
         
            <Button
              icon={arrowupShort}
              iconClassName={"ml-1 signup__icon"}
              content="Read More"
              onClick={() => navigate(`/about-us`)}
            />
          
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
