import React, { useEffect } from "react";
import "./HowItWorksSection.scss";
import { useDispatch, useSelector } from "react-redux";
import { getWorkFlows } from "../../redux/actionCreator";
import { workflowPath, workflows } from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const HowItWorksSection = ({ data }) => {
  const dispatch = useDispatch();

  const workFlowData = useSelector(workflows);
  const path = useSelector(workflowPath);

  useEffect(() => {
    dispatch(getWorkFlows());
  }, []);
  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 how__it__works__section md:pt-[60px] md:pb-[60px]">
      <h4 data-aos="fade-up" className="how__it__works__title mt-6 mb-2">
        HOW IT WORKS
      </h4>
      <h2 data-aos="fade-up" className="follow__our__journey__title">
        {data?.details?.how_it_work_title}
      </h2>
      <div class="grid grid-cols-12 gap-6 w-full mt-6">
        {workFlowData?.map((item, index) => {
          return (
            <div
              data-aos="flip-left"
              class={`xs:col-span-12 md:col-span-4 w-full journey__grid md:h-[400px]`}
            >
              <div className="details__section">
                <h2 className="heading mb-1">{item.title}</h2>
                <p className="description">{item.sub_title}</p>
              </div>
              <div className="image__container">
                <img
                  src={`${IMAGE_BASE_URL}${path}/${item?.image}`}
                  className="xs:w-[150px] md:w-[200px]"
                  alt={item?.image_alt_text}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowItWorksSection;
