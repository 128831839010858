import React from "react";
import "./Button.css";

const Button = ({ className, outlined, onClick, loader, content, contentClassName, icon, iconClassName }) => {
 
  const renderButtonContent = () => (
    <>
      <span className={`button__content ${contentClassName}`}>{content}</span>
      {icon && <img src={icon} className={iconClassName} alt="button icon" />}
    </>
  );

  return (
    <button
      className={`zlox__button ${className} ${outlined ? "outlined" : ""}`}
      onClick={onClick}
    >
      {loader ? <span className="loader"></span> : renderButtonContent()}
    </button>
  );
};


export default Button;
