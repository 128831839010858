import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Layout = ({ children }) => {
  const [isBusiness, setIsBusiness] = useState(false);

  return (
    <>
      <Header setIsBusiness={setIsBusiness} isBusiness={isBusiness} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
