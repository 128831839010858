import "./App.css";
import HomePage from "./pages/Home/HomePage";
import { useEffect } from "react";
import Aos from "aos";
import "./aos.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocialMediaPage from "./pages/SocialMedia/SocialMediaPage";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./commonComponents/Layout/Layout";
import AboutUs from "./pages/AboutUs/AboutUs";
import Discover from "./pages/Discover/Discover";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/Blogs/BlogDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { is_business } from "./redux/commonReducer";
import {
  getBusinessHome,
  getCMS,
  getCustomerHome,
} from "./redux/actionCreator";
import ContactUs from "./pages/ContactUs/ContactUs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import LegalInformation from "./pages/LegalInformation/LegalInformation";

function App() {
  const dispatch = useDispatch();

  const isBusiness = useSelector(is_business);

  useEffect(() => {
    dispatch(getCMS());
    if (isBusiness) {
      dispatch(getBusinessHome());
    } else {
      dispatch(getCustomerHome());
    }
  }, [isBusiness]);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/about-us"
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <Layout>
                <TermsAndConditions />
              </Layout>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="/legal-information"
            element={
              <Layout>
                <LegalInformation />
              </Layout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route
            path="/discover"
            element={
              <Layout>
                <Discover />
              </Layout>
            }
          />
          <Route
            path="/blogs"
            element={
              <Layout>
                <Blogs />
              </Layout>
            }
          />
          <Route
            path="/blogs/blog-details/:id"
            element={
              <Layout>
                <BlogDetails />
              </Layout>
            }
          />
        </Routes>
        <Routes>
          <Route path="/social-media-page" element={<SocialMediaPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

Aos.init();

export default App;
