import React, { useEffect } from "react";
import CommonDataSection from "../../commonComponents/CommonDataSection/CommonDataSection";
import { useDispatch, useSelector } from "react-redux";
import { getLegalInformations, getTermsCondition } from "../../redux/actionCreator";
import { terms_and_conditions } from "../../redux/commonReducer";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const termsData = useSelector(terms_and_conditions)

  useEffect(() => {
    dispatch(getTermsCondition());
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="about__main__section" className="overflow-x-hidden mt-3 legal_layout">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        <CommonDataSection
          title={termsData?.title}
          hideMainTitle
          description={termsData?.description}
          image={""}
        />
      </div>
    </section>
  );
};

export default TermsAndConditions;
