import React, { useEffect, useRef } from "react";
import logo from "../../assets/images/zlox_logo.svg";
import HowItWorksSection from "../Home/HowItWorksSection";
import Testimonials from "../Home/Testimonials";
import GetInTouch from "./GetInTouch";
import SocialMediaFooter from "./SocialMediaFooter";
import SocialMediaMainSection from "./SocialMediaMainSection";
import { getCMS } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  businessHomeData,
  cms_data,
  customerHomeData,
  is_business,
} from "../../redux/commonReducer";
import { useNavigate } from "react-router-dom";

const SocialMediaPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getInTouchRef = useRef(null)

  const executeScroll = () => getInTouchRef.current.scrollIntoView()    

  const isBusiness = useSelector(is_business);
  const customerData = useSelector(customerHomeData);
  const businessData = useSelector(businessHomeData);
  const cmsData = useSelector(cms_data);

  useEffect(() => {
    dispatch(getCMS());
    window.scrollTo(0, 0)
  }, []);

  return (
    <section>
      <div className="w-full flex align-center justify-center p-5 cursorPointer">
        <img src={logo} className="" alt="" onClick={()=>navigate("/")}/>
      </div>
      <SocialMediaMainSection data={cmsData?.info_details}executeScroll={executeScroll}/>
      <HowItWorksSection data={isBusiness ? businessData : customerData} />
      {/* {isBusiness ? "" : <Testimonials data={customerData} />} */}
      <GetInTouch getInTouchRef={getInTouchRef}/>
      <SocialMediaFooter cmsData={cmsData}/>
    </section>
  );
};

export default SocialMediaPage;
