import React from "react";
import "./SocialMediaMainSection.scss";
import upArrow from "../../assets/images/arrow-up-short.svg";
import upArrowBlack from "../../assets/images/arrow-up-short-black.png";
import Button from "../../commonComponents/Button/Button";
import { useSelector } from "react-redux";
import { cms_path } from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const SocialMediaMainSection = ({ data, executeScroll }) => {
  const path = useSelector(cms_path);
  return (
    <section id="sm__main__section" className="overflow-x-hidden">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        <div class="grid grid-cols-12 gap-6 w-full mt-8 h-[100%]">
          <div
            data-aos="fade-right"
            className="md:col-span-6 xs:col-span-12 flex flex-col align-center justify-evenly"
          >
            <h1 className="heading xs:text-center md:text-left">
              {data?.title} <br />
              <span className="second__heading">{data?.title_2}</span>
            </h1>
            <h2 className="sub__heading pt-2 xs:text-center md:text-left xs:wi-[100%] md:w-[65%]">
              {data?.sub_title}
            </h2>
            <span className="heading__description xs:wi-[100%] md:w-[80%] xs:text-center md:text-left">
              <div className="py-4"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></div>
            </span>
            {data?.features?.map((ele, i) => {
              return (
                <div className="flex gap-5 py-3 ">
                  <div className="w-10">
                    <img 
                      src={`${IMAGE_BASE_URL}${path}/${ele?.icon_image}`}
                      alt=""
                    />
                  </div>
                  <div className="w-80 md:min-w-[530px] text-justify" >
                    <h4 className="usp">{ele.title}</h4>
                    <h6
                      className="usp__description mt-2"
                      dangerouslySetInnerHTML={{ __html: ele?.description }}
                    ></h6>
                  </div>
                </div>
              );
            })}
            <div className="flex mt-3">
              <Button
                content={"Contact Us"}
                icon={upArrow}
                onClick={executeScroll}
              />
              <a href='https://calendly.com/zlox/introductory-call' target="_blank">
              <Button
                content={"Plan A Call"}
                icon={upArrowBlack}
                className={"mx-3 color-black"}
                outlined
              />
              </a>
            </div>
          </div>
          <div
            data-aos="fade-left"
            className="md:col-span-6 xs:col-span-12 h-[100%] w-full"
          >
            <div className="flex justify-center w-full">
              <img
                src={`${IMAGE_BASE_URL}${path}/${data?.page_image}`}
                className="md:h-[550px] xs:h-[100%]"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialMediaMainSection;
