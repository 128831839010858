import React, { useEffect } from "react";
import "./Header.css";
import logo from "../../assets/images/zlox_logo.svg";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import Button from "../../commonComponents/Button/Button";
import { Navbar } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { apply_modal, is_business } from "../../redux/commonReducer";
import { toggleBusinessAndCustomer } from "../../redux/actionCreator";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyModal from "../../pages/Home/ApplyModal";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isBusiness = useSelector(is_business);
  const applyModal = useSelector(apply_modal);
  const isBusinessLocal = localStorage.getItem("is_business");

  useEffect(() => {
    if (isBusinessLocal != null) {
      dispatch(
        toggleBusinessAndCustomer(isBusinessLocal == "true" ? true : false)
      );
      localStorage.setItem(
        "is_business",
        isBusinessLocal == "true" ? true : false
      );
    } else {
      localStorage.setItem("is_business", isBusiness ?? true);
      dispatch(toggleBusinessAndCustomer(isBusiness ?? true));
    }
  }, [isBusinessLocal]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleBusinessCustomerChange = () => {
    localStorage.setItem("is_business", !isBusiness);
    dispatch(toggleBusinessAndCustomer(!isBusiness));
    navigate("/");
  };

  return (
    <>
      <Navbar
        fluid
        rounded
        className="max-w-screen-xl mx-auto md:p-10 xs:p-4 sm:px-10 xs:px-4"
      >
        <Navbar.Brand onClick={() => navigate("/")} className="cursor-pointer">
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="Zlox" />
        </Navbar.Brand>
        <div className="flex md:order-2">
          <div className="flex xs:hidden md:flex">
            <Button
              content={isBusiness ? "Customer" : "Business"}
              className={"customer__button mr-2"}
              iconClassName={"ml-1 signup__icon"}
              outlined
              onClick={() => handleBusinessCustomerChange()}
            />
              <a href='https://calendly.com/zlox/introductory-call' target="_blank">
            <Button
              icon={arrowupShort}
              iconClassName={"ml-1 signup__icon"}
              content="Contact Now"
              // onClick={() => navigate("/contact-us")}
            />
            </a>
          </div>

          <Navbar.Toggle />
        </div>
        <Navbar.Collapse className="md:ml-12">
          <Navbar.Link
            active={location.pathname === "/"}
            className={`ff-semibold cursor-pointer text-base ${
              isActive("/") ? "link__active" : ""
            }`}
            onClick={() => navigate("/")}
          >
            Home
          </Navbar.Link>
          <Navbar.Link
            active={location.pathname === "/about-us"}
            className={`ff-semibold cursor-pointer text-base ${
              isActive("/about-us") ? "link__active" : ""
            }`}
            onClick={() => navigate("/about-us")}
          >
            About us
          </Navbar.Link>
          <Navbar.Link
            active={location.pathname === "/discover"}
            className={`ff-semibold cursor-pointer text-base ${
              isActive("/discover") ? "link__active" : ""
            }`}
            onClick={() => navigate("/discover")}
          >
            Discover
          </Navbar.Link>
          <Navbar.Link
            active={location.pathname === "/blogs"}
            className={`ff-semibold cursor-pointer text-base ${
              isActive("/blogs") ? "link__active" : ""
            }`}
            onClick={() => navigate("/blogs")}
          >
            Blogs
          </Navbar.Link>
          <Navbar.Link
            active={location.pathname === "/contact-us"}
            className={`ff-semibold cursor-pointer text-base ${
              isActive("/contact-us") ? "link__active" : ""
            }`}
            onClick={() => navigate("/contact-us")}
          >
            Contact us
          </Navbar.Link>
          <div className="flex w-full justify-center align-center xs:flex md:hidden">
            <Button
              content={isBusiness ? "Customer" : "Business"}
              className={"customer__button mr-2"}
              iconClassName={"ml-1 signup__icon"}
              outlined
              onClick={() => handleBusinessCustomerChange()}
            />
          
          <a href='https://calendly.com/zlox/introductory-call' target="_blank">
            <Button
              icon={arrowupShort}
              iconClassName={"ml-1 signup__icon"}
              content="Contact Now"
              // onClick={() => navigate("/contact-us")}
            />
            </a>
   

          </div>
        </Navbar.Collapse>
      </Navbar>
      {applyModal ? <ApplyModal /> : <></>}
    </>
  );
};

export default Header;
