import React, { useEffect } from "react";
import "./AboutMainSection.scss";
import { useDispatch, useSelector } from "react-redux";
import { about_data, updateRedux } from "../../redux/commonReducer";
import { getAboutUs } from "../../redux/actionCreator";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import CommonDataSection from "../../commonComponents/CommonDataSection/CommonDataSection";

const AboutMainSection = () => {
  const dispatch = useDispatch();

  const aboutData = useSelector(about_data);

  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  return (
    <section id="about__main__section" className="overflow-x-hidden mt-3">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        {/* <div className="w-full text-center flex flex-col justify-center items-center">
          <h4 className="ff-bold text-color-blue">ABOUT US</h4>
          <h1 className="ff-semibold md:text-3xl md:w-[85%] xs:w-[95%] mb-4 mt-4">
            {aboutData?.aboutus_details?.main_title}
          </h1>
          <p
            className="md:w-[75%] xs:w-[90%]"
            dangerouslySetInnerHTML={{
              __html: aboutData?.aboutus_details?.desc,
            }}
          ></p>
          <img
            src={`${IMAGE_BASE_URL}${"aboutus"}/${
              aboutData?.aboutus_details?.banner
            }`}
            alt=""
            className="mt-6"
          />
        </div> */}
        <CommonDataSection
          title={"ABOUT US"}
          mainTitle={aboutData?.aboutus_details?.main_title}
          description={aboutData?.aboutus_details?.desc}
          image={`${IMAGE_BASE_URL}${"aboutus"}/${
            aboutData?.aboutus_details?.banner
          }`}
        />
        <div className="mt-10">
          <h1 className="ff-semibold md:text-2xl xs:text-l md:w-[80%] xs:w-[90%]">
            {aboutData?.aboutus_details?.sub_title}
          </h1>
          <div class="grid grid-cols-12 gap-6 w-full mt-10">
            <div
              data-aos="fade-left"
              className="vision__mission__container md:col-span-6 xs:col-span-12 grid grid-cols-12"
            >
              <div className=" flex flex-col justify-evenly col-span-9">
                <h4 className="ff-bold text-color-blue mb-5">OUR VISION</h4>
                <h4 className="ff-semibold mb-5">
                  {aboutData?.aboutus_details?.vision_title}
                </h4>
                <p
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.aboutus_details?.vision_desc,
                  }}
                ></p>
              </div>
              <div className="col-span-3">
                <img
                  src={`${IMAGE_BASE_URL}${"aboutus"}/${
                    aboutData?.aboutus_details?.vision_icon
                  }`}
                  className=""
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="vision__mission__container md:col-span-6 xs:col-span-12 grid grid-cols-12"
            >
              <div className=" flex flex-col justify-evenly col-span-9">
                <h4 className="ff-bold text-color-blue mb-5">OUR MISSION</h4>
                <h4 className="ff-semibold mb-5">
                  {aboutData?.aboutus_details?.mission_title}
                </h4>
                <p
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.aboutus_details?.mission_desc,
                  }}
                ></p>
              </div>
              <div className="col-span-3">
                <img
                  src={`${IMAGE_BASE_URL}${"aboutus"}/${
                    aboutData?.aboutus_details?.mission_icon
                  }`}
                  className=""
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <h4 className="ff-bold text-color-blue">WHAT WE OFFER</h4>
          <h4 className="ff-semibold md:text-2xl xs:text-l what__we__offer__subheading">
            {aboutData?.aboutus_details?.what_we_offer_title}
          </h4>
          <div className="grid grid-cols-12 gap-0 w-full mt-10 px-10">
            {aboutData?.whatWeOffer?.map((ele, i) => {
              return (
                <div className="py-8 px-4 md:col-span-4 xs:col-span-12 md:border-r-[2px] mb-5  xs:border-r-none h-[220px] text-center flex flex-col items-center justify-evenly">
                  <img
                    src={`${IMAGE_BASE_URL}${"whatweoffer"}/${ele?.image}`}
                    width={35}
                    alt={ele?.image_alt_text}
                  />
                  <h4 className="ff-semibold md:text-l xs:text-m">
                    {ele?.title}
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: ele?.desc }}></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-10 text-center">
          <h4 className="ff-bold text-color-blue mb-4 md:text-xl2 xs:text-l">
            Meet Our Team
          </h4>
          <h4 className="ff-semibold md:text-2xl xs:text-xl mb-6">
            {aboutData?.aboutus_details?.team_title}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: aboutData?.aboutus_details?.team_desc,
            }}
          ></p>
          <div className="grid grid-cols-12 gap-6 mt-6">
            {aboutData?.teamMembers?.slice(0, 2)?.map((ele, i) => {
              return (
                <div className="xs:col-span-12 md:col-span-4 text-left w-full">
                  <img
                    src={`${IMAGE_BASE_URL}${"team"}/${ele?.image}`}
                    alt={ele?.image_alt_text}
                    className="w-full h-[400px] object-cover rounded-[20px]"
                  />
                  <h4 className="ff-semibold mt-4">{ele?.name}</h4>
                  <h6>{ele?.designation}</h6>
                </div>
              );
            })}
            <div className="xs:col-span-12 w-full h-[400px] md:col-span-4 text-left py-7 px-5 border-[1px] rounded-[20px]">
              <h4 className="ff-semibold text-xl">
              interested to join <br /> our team?
              </h4>
              <h4
                className="ff-bold text-xl apply__now__text"
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "apply_modal",
                      value: true,
                    })
                  )
                }
              >
                Apply Now
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMainSection;
