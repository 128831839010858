import React from "react";
import "./Footer.scss";
import footerImage from "../../assets/images/footer__image.svg";
import logo from "../../assets/images/Zlox-Logo-footer.svg";
import fb from "../../assets/images/fb.svg";
import instagram from "../../assets/images/instagram.svg";
import linkedin from "../../assets/images/linked.svg";
import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { cms_data } from "../../redux/commonReducer";
import { useSelector } from "react-redux";

const FooterSection = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const cmsData = useSelector(cms_data);

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
   
    <footer className="w-full mt-6 footer__section">
      <img src={footerImage} className="footer__image" />
      <div className="max-w-screen-xl mx-auto p-10 footer__container">
        <div className="links__Section">
          <div class="grid grid-cols-12 gap-6 w-full h-full">
            <div
              class={`xs:col-span-12 md:col-span-3 w-full h-full xs:flex xs:align-center xs:justify-center md:justify-start cursorPointer`}
            >
              <img src={logo} className="footer__logo" onClick={()=>navigate("/")} alt=""/>
            </div>
            <div
              class={`xs:col-span-12 md:col-span-7 grid grid-cols-12 gap-0 w-full h-full footer__links__section`}
            >
              <h4
                className={`ff-medium cursor-pointer md:col-span-2 xs:col-span-4 ${
                  isActive("/") ? "link__active" : ""
                }`}
                onClick={() => navigate("/")}
              >
                Home
              </h4>
              <h4
                className={`ff-medium cursor-pointer md:col-span-2 xs:col-span-4 ${
                  isActive("/about-us") ? "link__active" : ""
                }`}
                onClick={() => navigate("/about-us")}
              >
                About Us
              </h4>
              <h4
                className={`ff-medium cursor-pointer md:col-span-2 xs:col-span-4 ${
                  isActive("/discover") ? "link__active" : ""
                }`}
                onClick={() => navigate("/discover")}
              >
                Discover
              </h4>
              <h4
                className={`ff-medium cursor-pointer md:col-span-2 xs:col-span-4 ${
                  isActive("/blogs") ? "link__active" : ""
                }`}
                onClick={() => navigate("/blogs")}
              >
                Blog
              </h4>
              <h4
                className={`ff-medium cursor-pointer md:col-span-2 xs:col-span-4 ${
                  isActive("/contact-us") ? "link__active" : ""
                }`}
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </h4>
              {/* <h4 className="ff-medium cursor-pointer md:col-span-2 xs:col-span-4" onClick={()=> navigate("/login")}>
                Log in/Register
              </h4> */}
            </div>
            <div className="xs:col-span-12 md:col-span-2 grid grid-cols-12 gap-1 w-full h-full social__media xs:px-[40%] md:px-0">
              {cmsData?.general_details?.linkedln ? (
                <div
                  className="col-span-2 cursor-pointer"
                  onClick={() =>
                    window.open(
                      cmsData?.general_details?.linkedln,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <img src={linkedin} />
                </div>
              ) : (
                ""
              )}
              {cmsData?.general_details?.facebook ? (
                <div
                  className="col-span-2 cursor-pointer"
                  onClick={() =>
                    window.open(
                      cmsData?.general_details?.facebook,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <img src={fb} className="fb__icon" />
                </div>
              ) : (
                ""
              )}
              {cmsData?.general_details?.instagram ? (
                <div
                  className="col-span-2 cursor-pointer"
                  onClick={() =>
                    window.open(
                      cmsData?.general_details?.instagram,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <img src={instagram} />
                </div>
              ) : (
                ""
              )}
              {cmsData?.general_details?.twitter ? (
                <div
                  className="col-span-2 cursor-pointer"
                  onClick={() =>
                    window.open(
                      cmsData?.general_details?.twitter,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <img src={twitter} />
                </div>
              ) : (
                ""
              )}
              {cmsData?.general_details?.youtube ? (
                <div
                  className="col-span-2 cursor-pointer"
                  onClick={() =>
                    window.open(
                      cmsData?.general_details?.youtube,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <img src={youtube} className="youtube__icon" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="copyrigth__section md:flex-row xs:flex-col">
          <div className="copyright">
            {`©${cmsData?.company_info?.copyright_year}`} |{" "}
            {`${cmsData?.company_info?.company_name}`} |{" "}
            {`KVK: ${cmsData?.company_info?.kvk_number}`}
          </div>
          <div className="flex text-center">
            <div
              className="cursor-pointer"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms & Conditions
            </div>
            &nbsp; |&nbsp;
            <div
              className="cursor-pointer"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </div>
            &nbsp; |&nbsp;
            <div
              className="cursor-pointer"
              onClick={() => navigate("/legal-information")}
            >
              Legal Information
            </div>
          </div>
        </div>
      </div>
    </footer>
   
  );
};

export default FooterSection;
