import React, { useEffect } from "react";
import "./BlogSection.scss";
import arrowupShortBlack from "../../assets/images/arrow-up-short-black.png";
import Button from "../../commonComponents/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { blog_lists } from "../../redux/commonReducer";
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { getAllBlogs } from "../../redux/actionCreator";
import arrowupShort from "../../assets/images/arrow-up-short.svg";

const HomeBlogSection = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const blogData = useSelector(blog_lists);

  useEffect(() => {
    const raw = { page: 0, perPage: 3 };
    dispatch(getAllBlogs(raw));
  }, []);

  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 blog__section md:pt-[80px] md:pb-[100px]">
      <h4 className="blog__title" data-aos="fade-up">
        BLOG
      </h4>
      <h2 className="blog__heading xs:text-center" data-aos="fade-up">
        {data?.details?.blog_title}
      </h2>
      <div class="grid grid-cols-12 gap-6 w-full mt-6">
        {blogData?.map((item) => (
          <div
            class={`xs:col-span-12 md:col-span-4 w-full gap-2 cursor-pointer`}
            data-aos="flip-right"
            onClick={() => navigate(`/blogs/blog-details/${item?._id}`)}
          >
            <div className="w-full h-[250px]">
              <img
                src={IMAGE_BASE_URL + "blog/" + item?.blog_image}
                alt=""
                className="w-full h-[250px] object-cover rounded-[25px]"
              />
            </div>
            <h4 className="blog__tile__heading mb-2 mt-4 h-[120px]">
              {item.title}
            </h4>
            <h6
              className="blog__tile__description mb-4 h-[80px]"
              dangerouslySetInnerHTML={{
                __html:
                  item?.content?.length > 200
                    ? item?.content?.slice(0, 200) + "..."
                    : item?.content,
              }}
            ></h6>
            <div className="read__more__container">
              <Button
                icon={arrowupShortBlack}
                iconClassName={"ml-1 signup__icon"}
                content="READ MORE"
                className={"read__more"}
                contentClassName={"ff-medium"}
                onClick={() => navigate(`/blogs/blog-details/${item?._id}`)}
              />
            </div>
          </div>
        ))}
      </div>
      <Button onClick={() => navigate(`/blogs`)}
         content={"View All"}
         className={"flex  justify-center w-[120px] mt-5 md:mt-[80px]"}
         icon={arrowupShort}
         iconClassName={"ml-1 signup__icon"}
        ></Button>
    </div>
  );
};

export default HomeBlogSection;
