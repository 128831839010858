import React, { useEffect } from "react";
import AppSection from "../Home/AppSection";
import AboutMainSection from "./AboutMainSection";
import { useSelector } from "react-redux";
import {
  businessHomeData,
  customerHomeData,
  is_business,
} from "../../redux/commonReducer";

const AboutUs = () => {
  const isBusiness = useSelector(is_business);
  const customerData = useSelector(customerHomeData);
  const businessData = useSelector(businessHomeData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutMainSection />
      <AppSection
        data={isBusiness ? businessData?.details : customerData?.details}
        disableNewsLetter
        appData={isBusiness ? businessData?.appData : customerData?.appData}
      />
    </>
  );
};

export default AboutUs;
