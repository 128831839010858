import React, { useEffect, useState } from "react";
import "./blogs.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../redux/actionCreator";
import {
  blog_lists,
  blogs_total_count,
  businessHomeData,
  customerHomeData,
  is_business,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
export default function Blogs() {
  const [page, setPage] = useState(0);
  const [perPage] = useState(6);
  const [blogsLists, setBlogLists] = useState([]);
  const blogs = useSelector(blog_lists);
  const blogsCount = useSelector(blogs_total_count) 
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const isBusiness = useSelector(is_business);
  const customerData = useSelector(customerHomeData);
  const businessData = useSelector(businessHomeData);

  useEffect(() => {
    const data = { page, perPage };
    dispatch(
      getAllBlogs(data, (res) => {
        setBlogLists(res.blog);
      })
    );
    window.scrollTo(0, 0);
  }, []);

  const handleLoad = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    const data = { page: nextPage, perPage };
    dispatch(
      getAllBlogs(data, (res) => {
        setBlogLists((prevBlogs) => [...prevBlogs, ...res.blog]);
      })
    );
  };

  return (
    <section className="zlox__blogs">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%] mb-10 container ">
        <div className="heading">
          <h5 className="ff-bold text-color-blue">Blog</h5>
          <h1 className="ff-semibold md:text-2xl mb-4 mt-2">
            {isBusiness
              ? businessData?.details?.blog_title
              : customerData?.details?.blog_title}
          </h1>
        </div>
        <div className="grid grid-cols-12 gap-6">
          {blogsLists?.map((ele, i) => {
            return (
              <div
                class="Blog__grid xs:col-span-12 md:col-span-4"
                key={i}
                data-aos="fade-up"
                data-aos-duration={`1${i + 3}00`}
                onClick={() => navigate(`/blogs/blog-details/${ele?._id}`)}
              >
                <div className="image">
                  <img
                    className="main"
                    src={IMAGE_BASE_URL + "blog/" + ele?.blog_image}
                    alt=""
                  />
                </div>
                <h2>{ele?.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: ele?.content }}></div>
                <Link to={`/blogs/blog-details/${ele?._id}`}>
                  READ MORE{" "}
                  <img src="/assets/icons/arrow-up-short.svg" alt="" />
                </Link>
              </div>
            );
          })}
        </div>
        {blogsLists?.length < blogsCount && (
          <div className="view_more">
            <button className="btn-primary" onClick={handleLoad}>
              View More{" "}
              <img src="/assets/icons/arrow-up-short-white.svg" alt="" />
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
