import React, { useState } from "react";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const WhyZloxSection = ({ data }) => {
  const [expanded, setExpanded] = useState();
  return (
    <div className="grid grid-cols-12 gap-6 mt-10 why_zlox__section">
      <div className="xs:col-span-12 md:col-span-5">
        <img
          src={`${IMAGE_BASE_URL}${"discover"}/${
            data?.mainData?.why_zlox_image
          }`}
          alt={data?.mainData?.why_zlox_image_alt_text}
        />
      </div>
      <div className="xs:col-span-12 md:col-span-7 flex flex-col items-start justify-center">
        <div className="w-full">
          <h4 className="ff-bold text-color-blue text-l">WHY ZLOX</h4>
          <h4 className="ff-semibold text-2xl xs:w-[90%] md:w-[65%] mt-3">
            {data?.mainData?.why_zlox_title}
          </h4>
          {/* <p
            dangerouslySetInnerHTML={{ __html: data?.mainData?.why_zlox_desc }}
          ></p> */}
        </div>
        <div className="mt-6">
          {data?.whyZlox?.map((item, index) => (
            <div
              className={`w-100 ${
                index === data?.whyZlox?.length - 1 ? "" : "border-b-[1px]"
              } h-fit mb-3 flex flex-col p-2 duration-500`}
            >
              <div
                className="flex w-100 cursor-pointer items-center"
                onClick={() =>
                  expanded === index ? setExpanded() : setExpanded(index)
                }
              >
                <img
                  src={`${IMAGE_BASE_URL}${"discover"}/${item?.image}`}
                  width={30}
                  alt={item?.image_alt_text}
                />
                <h4 className="ml-2 ff-medium text-l">{item?.title}</h4>
              </div>
              {expanded === index ? (
                <p
                  className="text-sm ml-10 mt-5"
                  dangerouslySetInnerHTML={{ __html: item?.desc }}
                ></p>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyZloxSection;
