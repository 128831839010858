import React from "react";
import "./PartnerSection.scss";
import Button from "../../commonComponents/Button/Button";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Slider from "react-slick";

const PartnerSection = ({ data }) => {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="w-full partner__section__container">
      <div className="max-w-screen-xl mx-auto p-10 partner__section">
        {data?.partner?.length > 1 ? (
          <div className="slider-container">
            <Slider {...settings}>
              {data?.partner?.map((ele, i) => {
                return (
                  <div
                    class="grid grid-cols-12 gap-6 w-full h-full partner__tile px-5 my-5"
                    id={i}
                    key={i}
                  >
                    <div
                      data-aos="fade-left"
                      class={`xs:col-span-12 md:col-span-6 w-full h-full partner__logo__section`}
                    >
                      <h4 className="partner__text">{ele?.type}</h4>
                      <img
                        src={`${IMAGE_BASE_URL}${"home"}/${ele?.logo}`}
                        alt="logo"
                      />
                    </div>
                    <div
                      data-aos="fade-right"
                      class={`xs:col-span-12 md:col-span-6 w-full partner__details`}
                    >
                      <h2 className="partner__heading">{ele?.name}</h2>
                      <p
                        className="partner__description"
                        dangerouslySetInnerHTML={{ __html: ele?.desc }}
                      ></p>
                      <Button
                        icon={arrowupShort}
                        iconClassName={"ml-1 signup__icon"}
                        onClick={() =>
                          ele?.website
                            ? window.open(ele?.website, "_blank", "noreferrer")
                            : {}
                        }
                        content="Know More"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : (
          data?.partner?.map((ele, i) => {
            return (
              <div
                class="grid grid-cols-12 gap-6 w-full h-full partner__tile px-5 my-5"
                id={i}
                key={i}
              >
                <div
                  data-aos="fade-left"
                  class={`xs:col-span-12 md:col-span-6 w-full h-full partner__logo__section`}
                >
                  <h4 className="partner__text">{ele?.type}</h4>
                  <img
                    src={`${IMAGE_BASE_URL}${"home"}/${ele?.logo}`}
                    alt="logo"
                  />
                </div>
                <div
                  data-aos="fade-right"
                  class={`xs:col-span-12 md:col-span-6 w-full partner__details`}
                >
                  <h2 className="partner__heading">{ele?.name}</h2>
                  <p
                    className="partner__description"
                    dangerouslySetInnerHTML={{ __html: ele?.desc }}
                  ></p>
                  <Button
                    icon={arrowupShort}
                    iconClassName={"ml-1 signup__icon"}
                    onClick={() =>
                      ele?.website
                        ? window.open(ele?.website, "_blank", "noreferrer")
                        : {}
                    }
                    content="Know More"
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PartnerSection;
