import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateRedux } from "./commonReducer";

export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}
export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export const toggleBusinessAndCustomer = (value) => (dispatch) => {
  dispatch(updateRedux({ key: "is_business", value: value }));
};

export const addGetInTouch = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_getInTouch_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addGetInTouch`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "add_getInTouch_loading", value: false }));
      console.log(res.data, "res.data");
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "add_getInTouch_loading", value: false }));
      console.log(err);
    });
};

export const getCMS = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getCms`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "cms_data", value: res.data.data }));
        dispatch(updateRedux({ key: "cms_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getWorkFlows = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getWorkFlows`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "workflows", value: res.data.workflows }));
        dispatch(updateRedux({ key: "workflowPath", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCustomerHome = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}customer-home`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "customerHomeData", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "customerHomePath", value: res.data.path })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBusinessHome = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}business-home`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "businessHomeData", value: res.data.data })
        );
        dispatch(
          updateRedux({ key: "businessHomePath", value: res.data.path })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllBlogs = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getAllBlogs`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "blog_lists", value: res.data.blog }));
        dispatch(
          updateRedux({ key: "blogs_total_count", value: res.data.total_count })
        );
        callback && callback(res.data);
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBlogDetails = (id) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getBlogDetails/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(
          updateRedux({ key: "blog_details", value: res.data.blog_details })
        );
        dispatch(
          updateRedux({ key: "latest_blogs", value: res.data.latest_blogs })
        );
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAboutUs = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}aboutus`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "about_data", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDiscover = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}discover`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "discover_data", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addContactUs = (data, callback) => (dispatch) => {
  console.log(data, "data");
  dispatch(updateRedux({ key: "add_contact_us_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addContactUs`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "add_contact_us_loading", value: false }));
      console.log(res.data, "res.data");
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "add_contact_us_loading", value: false }));
      console.log(err?.config, "err");
    });
};

export const submitCareerEnquiry = (formdata, callback) => (dispatch) => {
  console.log(formdata, "data");
  dispatch(updateRedux({ key: "career_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}submitCareerEnquiry`, formdata)
    .then((res) => {
      dispatch(updateRedux({ key: "career_loading", value: false }));
      console.log(res.data, "res.data");
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "career_loading", value: false }));
      console.log(err?.config, "err");
    });
};

export const getPrivacyPolicy = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getPrivacyPolicy`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "privay_policy", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getLegalInformations = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getLegalInformations`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "legal_informations", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTermsCondition = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getTermsCcondition`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "terms_and_conditions", value: res.data.data }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const subscribeNewsLetter = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "subscribe_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}subscribeNewsLetter`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "subscribe_loading", value: false }));
      console.log(res.data, "res.data");
      if (res.data.status == true) {
        toast.success("Thank you for your interest in newsletter subscription.", {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "subscribe_loading", value: false }));
      console.log(err);
    });
};
