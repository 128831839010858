import React, { useEffect } from "react";
import CommonDataSection from "../../commonComponents/CommonDataSection/CommonDataSection";
import { useDispatch, useSelector } from "react-redux";
import { getLegalInformations } from "../../redux/actionCreator";
import { legal_informations } from "../../redux/commonReducer";

const LegalInformation = () => {
  const dispatch = useDispatch();
  const legalData = useSelector(legal_informations)

  useEffect(() => {
    dispatch(getLegalInformations());
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="about__main__section" className="overflow-x-hidden mt-3 legal_layout ">
      <div className="max-w-screen-xl  xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h">
        <CommonDataSection
          title={legalData?.title}
          hideMainTitle
          description={legalData?.description}
          image={""}
        />
      </div>
    </section>
  );
};

export default LegalInformation;
