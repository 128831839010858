import React, { useState } from "react";
import "./Services.scss";
import ScrollContainer from "react-indiana-drag-scroll";
import goIcon from "../../assets/images/service_go_icon.svg";
import popUpImage from "../../assets/images/popup_image.png";
import { Button, Modal } from "flowbite-react";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Slider from "react-slick";

const Services = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const onModalOpenHandler = (ele) => {
    if (ele?.attachment?.length) {
      setModalData(ele);
      setOpenModal(true);
    }
  };
  const handleClose = () => {
    setModalData("");
    setOpenModal(false);
  };

  var settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="ServicesSection" className="w-full overflow-x-hidden">
      <div className="max-w-screen-xl mx-auto p-10 service__section">
        <div className="grid grid-cols-12 gap-6">
          <div className="xs:col-span-12 md:col-span-4 flex flex-col items-start justify-center">
            <h6 className="ff-bold text-sm">OUR OBJECTIVE</h6>
            <h4 className="ff-semibold text-xl2 mt-3 mb-4">
              {data?.mainData?.objectives_title}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.mainData?.objectives_desc,
              }}
            ></p>
          </div>
          <div className="xs:col-span-12 md:col-span-8">
            <div class={`xs:col-span-12 md:col-span-6`}>
              <div className="slider-container w-full px-5">
                <Slider {...settings}>
                  {data?.objectives.map((el, i) => (
                    <div
                      key={i}
                      className="service__grid__container w-full"
                      onClick={() => onModalOpenHandler(el)}
                    >
                      <div className="service__grid md:w-[350px] xs:w-[100%] md:mr-[30px] xs:mr-[10px] flex flex-col">
                        <div className="flex items-end justify-end">
                          <img src={goIcon} width={40} alt="" />
                        </div>
                        <h4 className="ff-medium text-sm mb-4">{el?.title}</h4>
                        <h2 className="ff-medium text-xl2 mb-4 h-[80px]">
                          {el?.desc?.length > 50
                            ? el?.desc?.slice(0, 50) + "..."
                            : el?.desc}
                        </h2>
                        <div className="h-[200px]">
                          <img
                            src={`${IMAGE_BASE_URL}${"discover"}/${el?.image}`}
                            alt={el?.image_alt_text}
                            className="h-full"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size={"3xl"}
        show={openModal}
        onClose={() => handleClose()}
        dismissible
        className="border-none   focus:ring-0 service__modal"
      >
        <Modal.Body className="p-2 border-none  focus:ring-0 h-[80vh] test">
          {/* image or video condition check */}
          {["jpg", "jpeg", "png"]?.includes(
            modalData?.attachment?.split(".").pop()
          ) ? (
            <div className="flex items-center justify-center">
              <img
                src={`${IMAGE_BASE_URL}${"discover"}/${modalData?.attachment}`}
                className="h-[70vh]"
                alt=""
              />
            </div>
          ) : (
            <iframe
              className="video w-full rounded-lg  h-[70vh] border-none focus:ring-0"
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`${IMAGE_BASE_URL}${"discover"}/${modalData?.attachment}`}
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Services;
