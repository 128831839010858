import React, { useEffect } from "react";
import AppSection from "../Home/AppSection";
import HowItWorksSection from "../Home/HowItWorksSection";
import DiscoverMainSection from "./DiscoverMainSection";
import Services from "./Services";
import { useDispatch, useSelector } from "react-redux";
import { getDiscover } from "../../redux/actionCreator";
import { discover_data } from "../../redux/commonReducer";

const Discover = () => {
  const dispatch = useDispatch();

  const data = useSelector(discover_data);

  useEffect(() => {
    dispatch(getDiscover());
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DiscoverMainSection data={data} />
      <Services data={data} />
      <HowItWorksSection />
      <AppSection
        disableNewsLetter
        data={data?.appMainData}
        appData={data?.appData}
      />
    </>
  );
};

export default Discover;
