import React, { useRef } from "react";
import "./Testimonials.scss";
import { Carousel } from "flowbite-react";
import leftControl from "../../assets/images/left__control.svg";
import rightControl from "../../assets/images/right__control.svg";
import invertedCommas from "../../assets/images/inverted-commas.svg";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const Testimonials = ({ data }) => {
  const carousel_ref = useRef();

  const Prev = () => (
    <div className="prev__control">
      <img src={leftControl} />
    </div>
  );
  const Next = () => (
    <div className="next__control">
      <img src={rightControl} />
    </div>
  );

  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 testimonials__section overflow-x-hidden">
      <div class="grid grid-cols-12 gap-6 w-full mt-8">
        <div data-aos="fade-left" className="md:col-span-5 xs:col-span-12">
          <img
          className="md:h-[375px]"
            src={`${IMAGE_BASE_URL}${"home"}/${
              data?.details?.testimonial_image
            }`}
            alt=""
          />
        </div>
        <div
          data-aos="fade-right"
          className="md:col-span-7 xs:col-span-12 flex flex-col"
        >
          <h4 className="testimonials__title">TESTIMONIALS</h4>
          <h4 className="testimonials__subtitle">
            {data?.details?.testimonial_title}
          </h4>
          <div className="w-100 flex-1 xs:h-[500px] md:h-[300px]">
            <Carousel
            indicators={false}
              className="mt-3 testimonial__slider carousel__container xs:h-[500px] md:h-[300px]"
              leftControl={data?.testimonials?.length > 0 ? <Prev /> : ""}
              rightControl={data?.testimonials?.length > 0 ? <Next /> : ""}
              
            >
              {data?.testimonials?.map((ele, i) => {
                return (
                  <div className="testimonials__slider__container ">
                    <img
                      src={invertedCommas}
                      className="inverted__commas_image"
                      alt=""
                    />
                    <h4 className="testimonials__text mt-3">
                      {ele?.testimonial_text}
                    </h4>
                    <div className="testimonials__profile">
                      <img
                        src={`${IMAGE_BASE_URL}${"home"}/${ele?.image}`}
                        alt=""
                      />
                      <div className="details__container">
                        <h4 className="name">{ele?.name}</h4>
                        <h4 className="company">{ele?.designation}</h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
