import React from "react";
import "./SocialMediaFooter.scss";
import logo from "../../assets/images/zlox_logo.svg";
import { useNavigate } from "react-router-dom";

const SocialMediaFooter = ({cmsData}) => {
  const navigate = useNavigate();
  return (
    <section id="SocialMediaFooter">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        <div class="grid grid-cols-12 gap-6 w-full h-[100%]">
          <div className="col-span-6 flex justify-end items-center cursorPointer">
            <img src={logo} alt="" onClick={()=>navigate("/")}/>
          </div>
          <div className="col-span-6 flex flex-col justify-center px-5 items-start details__conatiner">
            <h6>{`${cmsData?.company_info?.company_name}`}</h6>location	
            <h6>{`KVK: ${cmsData?.company_info?.kvk_number}`}</h6>
            <h6>{`©${cmsData?.company_info?.copyright_year}`} | {`${cmsData?.company_info?.location}`} </h6>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialMediaFooter;
