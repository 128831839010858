import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import { Label, Textarea, TextInput } from "flowbite-react";
import Button from "../../commonComponents/Button/Button";
import phone from "../../assets/images/telephone.svg";
import envelop from "../../assets/images/envelope.svg";
import location from "../../assets/images/location.svg";
import { useDispatch, useSelector } from "react-redux";
import { addContactUs, getCMS } from "../../redux/actionCreator";
import { add_contact_us_loading, cms_data } from "../../redux/commonReducer";

const ContactUs = () => {
  const dispatch = useDispatch();

  const cmsData = useSelector(cms_data);
  const loading = useSelector(add_contact_us_loading);

  const [data, setData] = useState({
    full_name: "",
    email: "",
    message: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getCMS());
    window.scrollTo(0, 0);
  }, []);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.full_name?.trim()?.length) {
      temp = {
        ...temp,
        full_name: "Please enter name",
      };
    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: "Please enter email",
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email?.trim())) {
        temp = {
          ...temp,
          email: "Please enter valid email",
        };
      }
    }
    if (!data?.message?.trim()?.length) {
      temp = {
        ...temp,
        message: "Please enter message",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      let raw = {
        full_name: data?.full_name?.trim(),
        email: data?.email?.trim(),
        message: data?.message?.trim(),
      };
      dispatch(
        addContactUs(raw, (res) => {
          if (res) {
            setData({
              full_name: "",
              email: "",
              message: "",
            });
          }
        })
      );
    }
  };

  return (
    <section id="ContactUs" className="overflow-x-hidden mt-3">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        <h4 className="ff-bold text-color-blue text-m">CONTACT US</h4>
        <h2 className="ff-semibold text-2xl mt-3">Get in Touch</h2>
        <div className="mt-4 contact__container p-6 grid grid-cols-12 gap-6">
          <div className="xs:col-span-12 md:col-span-6 flex flex-col justify-between">
            <div>
              <h4 className="text-l">{cmsData?.contact_details?.title}</h4>
              <h4 className="ff-medium text-3xl">
                {cmsData?.contact_details?.title_2}
              </h4>
              <h4 className="text-xl2 mb-6">
                {cmsData?.contact_details?.title_3}
              </h4>
              <Button
                content={"Schedule Call"}
                className={
                  "flex items-center justify-center w-[150px] xs:mb-10 md:mb-0"
                }
              />
            </div>
            <div className="text-sm ff-medium">
              <div className="flex items-start mb-2">
                <img src={location} alt="" />
                <p className="ml-3">{cmsData?.contact_details?.address}</p>
              </div>
              <div className="flex items-start mb-2">
                <img src={phone} alt="" />
                <p className="ml-3">
                  Phone Number: <br />
                  {cmsData?.contact_details?.telephone}
                </p>
              </div>
              <div className="flex items-start">
                <img src={envelop} className="contact__us__envelop" alt="" />
                <p className="ml-3">
                  Email Contact: <br />
                  {cmsData?.contact_details?.email}
                </p>
              </div>
            </div>
          </div>
          <div className="xs:col-span-12 md:col-span-6 form__container rounded-[10px]">
            <form className="grid grid-cols-12 gap-6 w-full p-5">
              <div className="md:col-span-12 xs:col-span-12">
                <div className="mb-2 block">
                  <Label
                    htmlFor="email1"
                    value="Full Name"
                    className="ff-medium"
                  />
                </div>
                <TextInput
                  id="email1"
                  type="text"
                  placeholder="John Smith"
                  required
                  onChange={(e) =>
                    onChangeHandler("full_name", e?.target?.value)
                  }
                  value={data?.full_name}
                />
                <small id="error" className="error">
                  {err?.full_name}
                </small>
              </div>
              <div className="md:col-span-12 xs:col-span-12">
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Email" className="ff-medium" />
                </div>
                <TextInput
                  id="email1"
                  type="email"
                  placeholder="John.Smith@gmail.com"
                  required
                  onChange={(e) => onChangeHandler("email", e?.target?.value)}
                  value={data?.email}
                />
                <small id="error" className="error">
                  {err?.email}
                </small>
              </div>
              <div className="md:col-span-12 xs:col-span-12">
                <div className="mb-2 block">
                  <Label
                    htmlFor="password1"
                    value="Message"
                    className="ff-medium"
                  />
                </div>
                <Textarea
                  id="password1"
                  type="textarea"
                  className="h-[100px]"
                  required
                  placeholder="Type Here"
                  onChange={(e) => onChangeHandler("message", e?.target?.value)}
                  value={data?.message}
                />
                <small id="error" className="error">
                  {err?.message}
                </small>
              </div>
              <div >
                <Button
                  content={"Contact Us"}
                  type={"submit"}
                  className={"flex items-center justify-center w-[120px]"}
                  loader={loading}
                  onClick={(e) => onSubmitHandler(e)}
                />
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
