import React, { useState } from "react";
import plus from "../../assets/images/plus.svg";
import dash from "../../assets/images/dash.svg";
import "./DiscoverMainSection.scss";
import WhyZloxSection from "./WhyZloxSection";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const DiscoverMainSection = ({ data }) => {
  const [expanded, setExpanded] = useState(0);

  return (
    <section id="discover__main__section" className="overflow-x-hidden mt-3">
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%] mb-10">
        <div className="w-full text-center flex flex-col justify-center items-center">
          <h4 className="ff-bold text-color-blue">DISCOVER</h4>
          <h1 className="ff-semibold md:text-3xl md:w-[85%] xs:w-[95%] mb-4 mt-4">
            What is Zlox
          </h1>
        </div>
        {data?.faqs?.map((item, index) => (
          <div
            className={`w-100 border-[1px] h-fit mb-3 rounded-[10px] flex flex-col p-7 duration-500 accordion__item ${
              expanded === index ? "selected" : ""
            }`}
          >
            <div
              className="flex w-100 justify-between cursor-pointer"
              onClick={() =>
                expanded === index
                  ? setExpanded()
                  : setExpanded(index)
              }
            >
              <h4 className="ff-semibold text-l">{item.question}</h4>
              <h4 className="expand__icons flex items-center justify-center">
                {expanded === index ? <img src={dash} /> : <img src={plus} />}
              </h4>
            </div>

            {expanded === index ? (
              <div className="grid grid-cols-12 gap-6 w-full mt-4">
                <div
                  className="xs:col-span-12 md:col-span-8 text-m"
                  dangerouslySetInnerHTML={{ __html: item?.answer }}
                ></div>
                <div className="xs:col-span-12 md:col-span-4 flex items-center justify-end">
                  <img
                    src={`${IMAGE_BASE_URL}${"faq"}/${item?.image}`}
                    alt={item?.image_alt_text}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
        <WhyZloxSection data={data} />
      </div>
    </section>
  );
};

export default DiscoverMainSection;
