import React, { useState } from "react";
import "./PricingSection.scss";
import Button from "../../commonComponents/Button/Button";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import tickImage from "../../assets/images/tick__image.png";
import { Carousel } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const PricingSection = ({ data }) => {
  const navigate = useNavigate();

  const [selectedPricing, setSelectedPricing] = useState("");

  return (
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-6 pricing__section">
      <h4 className="pricing__title" data-aos="fade-up">
        PRICING
      </h4>
      <h2 className="pricing__heading" data-aos="fade-up">
        Choose your plan
      </h2>
      <div
        className="xs:hidden md:grid grid-cols-12 gap-6 w-full mt-6"
        data-aos="fade-up"
      >
        {data?.pricing?.map((item, index) => (
          <div
            className={`xs:col-span-12 md:col-span-3 w-full gap-2 pricing__grid ${
              selectedPricing === index ? "selected" : ""
            }`}
            onClick={() => setSelectedPricing(index)}
            // data-aos="flip-right"
          >
            <h4 className="ff-medium">{item.plan_name}</h4>
            <h4>
              <span className="price">€{item.price}</span>{" "}
              <span className="per__month ff-medium">/ month</span>
            </h4>
            <h4 className="description">{item.features}</h4>
            <div className="hr__line"></div>
            <h4 className="details">{item.business}</h4>
            <div className="min-h-[250px] scroller_layout">
            {item.packages?.map((el) => (
              <div className="grid grid-cols-12 gap-2 w-full  mt-2 features__grid">
                <div className="col-span-1">
                  <img src={tickImage} width={30} alt="" />
                </div>
                <div className="col-span-9">
                  <h4 className="name ff-medium">{el.name}</h4>
                </div>
                <div className="col-span-2 number__container">
                  <h4 className="ff-medium">{el.count}</h4>
                </div>
              </div>
            ))}
             </div>
            {selectedPricing === index ? (
              <div className="contact__us__container">
                <Button
                  icon={arrowupShort}
                  iconClassName={"ml-1 signup__icon"}
                  content="Contact Us"
                  className={"download_app"}
                  onClick={() => navigate("/contact-us")}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      <div className="xs:grid md:hidden grid-cols-12 gap-9 h-full mt-6">
        <div
          data-aos="fade-up"
          className={`xs:col-span-12 md:col-span-6 xs:h-[500px]`}
        >
          <Carousel
            leftControl=" "
            rightControl=" "
            className="pricing__grid__mobile"
          >
            {data?.pricing?.map((item, index) => (
              <div
                className={`xs:col-span-12 h-full pricing__grid pricing__grid__mobile ${
                  selectedPricing === index ? "selected" : ""
                }`}
                onClick={() => setSelectedPricing(index)}
                // data-aos="flip-right"
              >
                <h4>{item.plan_name}</h4>
                <h4>
                  <span className="price">€{item.price}</span>{" "}
                  <span className="per__month">/ month</span>
                </h4>
                <h4 className="description">{item.features}</h4>
                <div className="hr__line"></div>
                <h4 className="details">{item.business}</h4>
                <div className="min-h-[270px] scroller_layout ">
                {item.packages?.map((el) => (
                  <div className="grid grid-cols-12 gap-2 w-full mt-2 features__grid">
                    <div className="col-span-1">
                      <img src={tickImage} width={30} alt="" />
                    </div>
                    <div className="col-span-9">
                      <h4 className="name">{el.name}</h4>
                    </div>
                    <div className="col-span-2 number__container">
                      <h4>{el.count}</h4>
                    </div>
                  </div>
                ))}
               </div>
                {selectedPricing === index ? (
                  <div className="contact__us__container">
                    <Button
                      icon={arrowupShort}
                      iconClassName={"ml-1 signup__icon"}
                      content="Contact Us"
                      className={"download_app"}
                      onClick={() => navigate("/contact-us")}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}

          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
