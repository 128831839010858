import React from "react";
import "./MainSection.scss";
import arrowupShort from "../../assets/images/arrow-up-short.svg";
import Button from "../../commonComponents/Button/Button";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { Carousel } from "flowbite-react";
import Slider from "react-slick";
import Responsive from "./ReactSlick";
import { useNavigate } from "react-router-dom";
import { is_business } from "../../redux/commonReducer";
import { useSelector } from "react-redux";

const MainSection = ({ data, path, executeScroll }) => {
  const isBusiness = useSelector(is_business);

  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleClickLogin = () => {
    window.open("https://www.zlox.nl", "_blank").focus();
  };

  return (
    <div className="max-w-screen-xl mx-auto p-10 main__section">
      {data?.banners?.length > 1 ? (
        <div className="slider-container w-full">
          <Slider {...settings}>
            {data?.banners?.map((ele, i) => {
              return (
                <div class="grid grid-cols-12 gap-4 w-full main__section__tile">
                  <div
                    data-aos="fade-right"
                    className="md:col-span-6 xs:col-span-12 flex flex-col align-center justify-center "
                  >
                    <h1 className="main__text xl:text-h1 xs:text-4xl xs:text-center md:text-left">
                      {ele?.title}
                    </h1>
                    <h4 className="description__text xl:text-xl xs:text-center md:text-left">
                      {ele?.desc}
                    </h4>
                    <div className="flex xs:justify-center md:justify-start">
                      <a
                        href="https://calendly.com/zlox/introductory-call"
                        target="_blank"
                      >
                        <Button
                          icon={arrowupShort}
                          iconClassName={"ml-1 signup__icon"}
                          content="Contact Now"
                          // onClick={() => navigate("/contact-us")}
                        />
                      </a>
                      <Button
                        icon={arrowupShort}
                        iconClassName={"ml-1 signup__icon"}
                        content={isBusiness ? "Login" : "Download App"}
                        className={"download_app"}
                        onClick={() =>
                          isBusiness ? handleClickLogin() : executeScroll()
                        }
                      />
                    </div>
                  </div>
                  <div
                    data-aos="fade-left"
                    className="md:col-span-6 xs:col-span-12 main__image__container"
                  >
                    <img
                      src={`${IMAGE_BASE_URL}${"home"}/${ele?.image}`}
                      className="main__image"
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      ) : (
        data?.banners?.map((ele, i) => {
          return (
            <div class="grid grid-cols-12 gap-4 w-full main__section__tile">
              <div
                data-aos="fade-right"
                className="md:col-span-6 xs:col-span-12 flex flex-col align-center justify-center "
              >
                <h1 className="main__text xl:text-h1 xs:text-4xl xs:text-center md:text-left">
                  {ele?.title}
                </h1>
                <h4 className="description__text xl:text-xl xs:text-center md:text-left">
                  {ele?.desc}
                </h4>
                <div className="flex xs:justify-center md:justify-start">
                  <a
                    href="https://calendly.com/zlox/introductory-call"
                    target="_blank"
                  >
                    <Button
                      icon={arrowupShort}
                      iconClassName={"ml-1 signup__icon"}
                      content="Contact Now"
                      // onClick={() => navigate("/contact-us")}
                    />
                  </a>
                  <Button
                    icon={arrowupShort}
                    iconClassName={"ml-1 signup__icon"}
                    content={isBusiness ? "Login" : "Download App"}
                    className={"download_app"}
                    onClick={() =>
                      isBusiness ? handleClickLogin() : executeScroll()
                    }
                  />
                </div>
              </div>
              <div
                data-aos="fade-left"
                className="md:col-span-6 xs:col-span-12 main__image__container"
              >
                <img
                  src={`${IMAGE_BASE_URL}${"home"}/${ele?.image}`}
                  className="main__image"
                  alt=""
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default MainSection;
