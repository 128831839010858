import React from "react";

const CommonDataSection = ({
  title,
  mainTitle,
  description,
  image,
  hideMainTitle,
}) => {
  return (
    <div className="w-full text-center flex flex-col justify-center items-center">
      <h4 className="ff-bold text-color-blue">{title}</h4>
      <h1 className="ff-semibold md:text-3xl md:w-[85%] xs:w-[95%] mb-4 mt-4">
        {mainTitle
          ? mainTitle
          : hideMainTitle
          ? ""
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices pharetra ex a semper. Vestibulum ante ipsum primis"}
      </h1>
      <p
        className="md:w-[75%] xs:w-[90%]"
        dangerouslySetInnerHTML={{
          __html: description
            ? description
            : "We’re more than just architects of a digital city passport; we’re curators of connections, bridging the divide between businesses and city wanderers. Our team, a blend of digital crafters and strategic visionaries, ensures that whether you’re shopping non a local bus or exploring international deals, Zlox remains your go-to companion. As we amplify businesses, from cozy coffee shops to global brands, we’re constantly weaving a narrative where every tap, every click, is a step towards a new urban experience.",
        }}
      ></p>
      {image ? <img src={image} alt="" className="mt-6" /> : ""}
    </div>
  );
};

export default CommonDataSection;
