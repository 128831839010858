import { FileInput, Label, Modal, TextInput } from "flowbite-react";
import closeIcon from "../../assets/images/close.svg";
import uploadIcon from "../../assets/images/upload-cloud.svg";
import Button from "../../commonComponents/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  apply_modal,
  career_loading,
  updateRedux,
} from "../../redux/commonReducer";
import { useState } from "react";
import { submitCareerEnquiry } from "../../redux/actionCreator";

const ApplyModal = () => {

  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();

  const applyModal = useSelector(apply_modal);
  const loading = useSelector(career_loading);

  const [data, setData] = useState({
    full_name: "",
    email: "",
    resume: "",
  });
  const [err, setErr] = useState({});

  const handleClose = () => {
    dispatch(
      updateRedux({
        key: "apply_modal",
        value: false,
      })
    );
    setData({
      full_name: "",
      email: "",
      resume: "",
    });
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const onChangeHandler = (key, value) => {
    if (key === "resume") {
      setSelectedFile(value)
    }
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.full_name?.trim()?.length) {
      temp = {
        ...temp,
        full_name: "Please enter name",
      };
    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: "Please enter email",
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email?.trim())) {
        temp = {
          ...temp,
          email: "Please enter valid email",
        };
      }
    }
    if (data?.resume == "") {
      temp = {
        ...temp,
        resume: "Please upload resume",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      let formData = new FormData();
      formData.append("full_name", data?.full_name?.trim());
      formData.append("email", data?.email?.trim());
      formData.append("resume", data?.resume);

      dispatch(
        submitCareerEnquiry(formData, (res) => {
          if (res) {
            handleClose();
          }
        })
      );
    }
  };

  return (
    <Modal
      show={applyModal}
      onClose={() => handleClose()}
      dismissible
      className="border-none focus:ring-0 apply__modal"
    >
      <Modal.Body className="">
        <div className="w-full flex items-center justify-end cursor-pointer">
          <img
            src={closeIcon}
            width={15}
            onClick={() => handleClose()}
            alt=""
          />
        </div>
        <form className="grid grid-cols-12 gap-6 w-full p-5">
          <div className="md:col-span-12 xs:col-span-12">
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="Full Name"
                className="ff-semibold"
              />
            </div>
            <TextInput
              id="email1"
              type="text"
              placeholder="John Smith"
              required
              onChange={(e) => onChangeHandler("full_name", e?.target?.value)}
              value={data?.full_name}
            />
            <small id="error" className="error">
              {err?.full_name}
            </small>
          </div>
          <div className="md:col-span-12 xs:col-span-12">
            <div className="mb-2 block">
              <Label htmlFor="email1" value="Email" className="ff-semibold" />
            </div>
            <TextInput
              id="email1"
              type="email"
              placeholder="John.Smith@gmail.com"
              required
              onChange={(e) => onChangeHandler("email", e?.target?.value)}
              value={data?.email}
            />
            <small id="error" className="error">
              {err?.email}
            </small>
          </div>
          <div className="md:col-span-12 xs:col-span-12">
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="Upload Resume"
                className="ff-semibold"
              />
            </div>
            <div className="mb-2 block">
              <Label
                htmlFor="dropzone-file"
                className="upload__label flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 flex">
                    <img src={uploadIcon} className="mr-3" alt="Upload Icon" />
                    <span className="font-semibold">
                      Drop your file here to upload or click browse
                    </span>
                  </p>
                </div>
                <FileInput
                  id="dropzone-file"
                  className="hidden"
                  accept=".doc,.docx,.pdf,.PDF" 
                  onChange={(e) => onChangeHandler("resume", e?.target?.files[0])}
                />
              </Label>

             
              {selectedFile && (
                <div className="mt-4 p-4 border rounded-md bg-gray-50 dark:bg-gray-700">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium  dark:text-gray-300">
                      Selected File: {selectedFile.name}
                    </p>
                    <button
                      className="ml-4 text-md font-semibold text-red-600 hover:text-red-800"
                      onClick={handleRemoveFile}
                    >
                      Remove
                    </button>
                  </div>
                  <p className="text-md  dark:text-gray-400 mt-2">
                    File Size: {(selectedFile.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              )}
            </div>
            <small id="error" className="error">
              {err?.resume}
            </small>
          </div>
          <div className="md:col-span-12 xs:col-span-12 flex items-center ">
            <Button
              content={"Submit"}
              type={"submit"}
              className={"flex items-center justify-center w-[120px]"}
              onClick={(e) => onSubmitHandler(e)}
              loader={loading}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ApplyModal;
