import React from "react";
import "./ClientsSection.scss";
import { IMAGE_BASE_URL } from "../../constants/configuration";

const ClientsSection = ({ data }) => {
  return (
    console.log(data,'data'),
    <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 clients__section">
      <h4 className="clients__title mt-6 mb-2" data-aos="fade-up">
        OUR CLIENTS
      </h4>
      <h2 className="clients__sub__title" data-aos="fade-up">
        {data?.details?.client_title}
      </h2>
      <h4
        className="clients__description xs:w-[100%] md:w-[70%] xs:text-center md:text-center"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: data?.details?.client_desc }}
      ></h4>
      <div class="grid grid-cols-12 gap-6 w-full mt-8">
        {data?.clients?.map((ele) => {
          return (
            
              <div
              className="md:col-span-3 xs:col-span-4 clients__logo"
              data-aos="flip-left"
            >
              <a href={ele?.website} target="_blank">
              <img src={`${IMAGE_BASE_URL}${"home"}/${ele?.logo}`} />
              <span>{ele?.name}</span>
              </a>
            </div>
            
          );
        })}
      </div>
    </div>
  );
};

export default ClientsSection;
