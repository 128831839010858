import React, { useState } from "react";
import "./GetInTouch.scss";
import handshakeImage from "../../assets/images/Handshake.svg";
import { Checkbox, Label, TextInput } from "flowbite-react";
import Button from "../../commonComponents/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addGetInTouch } from "../../redux/actionCreator";
import { add_getInTouch_loading } from "../../redux/commonReducer";

const GetInTouch = ({ getInTouchRef }) => {
  const dispatch = useDispatch();

  const loading = useSelector(add_getInTouch_loading);

  const [data, setData] = useState({
    full_name: "",
    company_name: "",
    email: "",
    mobile: "",
    accept_terms: false,
  });
  const [err, setErr] = useState({});

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.full_name?.trim()?.length) {
      temp = {
        ...temp,
        full_name: "Please enter name",
      };
    }
    if (!data?.company_name?.trim()?.length) {
      temp = {
        ...temp,
        company_name: "Please enter company name",
      };
    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: "Please enter email",
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email?.trim())) {
        temp = {
          ...temp,
          email: "Please enter valid email",
        };
      }
    }
    if (!data?.mobile?.trim()?.length) {
      temp = {
        ...temp,
        mobile: "Please enter phone number",
      };
    }
    if (data?.accept_terms == false) {
      temp = {
        ...temp,
        accept_terms: "Please accept terms and conditions",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      let raw = {
        full_name: data?.full_name?.trim(),
        company_name: data?.company_name?.trim(),
        email: data?.email?.trim(),
        mobile: data?.mobile?.trim(),
        accept_terms: data?.accept_terms,
      };
      dispatch(
        addGetInTouch(raw, (res) => {
          if (res) {
            setData({
              full_name: "",
              company_name: "",
              email: "",
              mobile: "",
              accept_terms: false,
            });
          }
        })
      );
    }
  };

  return (
    <section
      id="get__in__touch"
      className="overflow-x-hidden"
      ref={getInTouchRef}
    >
      <div className="max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%]">
        <div class="grid grid-cols-12 gap-6 w-full mt-8 h-[100%]">
          <div
            data-aos="fade-left"
            className="md:col-span-5 xs:col-span-12 h-[100%]"
          >
            <img src={handshakeImage} className="md:h-[400px] xs:h-[100%]" />
          </div>
          <div
            data-aos="fade-right"
            className="md:col-span-7 xs:col-span-12 flex flex-col align-center justify-evenly"
          >
            <h4 className="get__in__touch__heading">Get in Touch</h4>
            <h4 className="get__in__touch__heading__subheading">
              Set Up Your Business in 3 Simple Steps and Get Noticed…
            </h4>
            <div>
              <form className="grid grid-cols-12 gap-6 w-full">
                <div className="md:col-span-6 xs:col-span-12">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="email1"
                      value="Full Name"
                      className="ff-medium"
                    />
                  </div>
                  <TextInput
                    id="email1"
                    type="text"
                    placeholder="John Smith"
                    required
                    onChange={(e) =>
                      onChangeHandler("full_name", e?.target?.value)
                    }
                    value={data?.full_name}
                  />
                  <small id="error" className="error">
                    {err?.full_name}
                  </small>
                </div>
                <div className="md:col-span-6 xs:col-span-12">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="password1"
                      value="Company Name"
                      className="ff-medium"
                    />
                  </div>
                  <TextInput
                    id="password1"
                    type="text"
                    required
                    placeholder="xyz"
                    onChange={(e) =>
                      onChangeHandler("company_name", e?.target?.value)
                    }
                    value={data?.company_name}
                  />
                  <small id="error" className="error">
                    {err?.company_name}
                  </small>
                </div>
                <div className="md:col-span-6 xs:col-span-12">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="email1"
                      value="Email"
                      className="ff-medium"
                    />
                  </div>
                  <TextInput
                    id="email1"
                    type="email"
                    placeholder="John.Smith@gmail.com"
                    required
                    onChange={(e) => onChangeHandler("email", e?.target?.value)}
                    value={data?.email}
                  />
                  <small id="error" className="error">
                    {err?.email}
                  </small>
                </div>
                <div className="md:col-span-6 xs:col-span-12">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="password1"
                      value="Mobile"
                      className="ff-medium"
                    />
                  </div>
                  <TextInput
                    id="password1"
                    type="number"
                    required
                    placeholder="Phone Number"
                    onChange={(e) =>
                      onChangeHandler("mobile", e?.target?.value)
                    }
                    value={data?.mobile}
                  />
                  <small id="error" className="error">
                    {err?.mobile}
                  </small>
                </div>
                <div className="col-span-12">
                  <div>
                    <Checkbox
                      id="remember"
                      value={data?.accept_terms}
                      checked={data?.accept_terms}
                      onChange={(e) =>
                        onChangeHandler("accept_terms", e?.target?.checked)
                      }
                    />
                    <Label htmlFor="remember" className="mx-3 ff-medium">
                      Accept the terms & conditions
                    </Label>
                  </div>
                  <small id="error" className="error">
                    {err?.accept_terms}
                  </small>
                </div>

                <div>
                  <Button
                    content={"Submit"}
                    className={"submit__button"}
                    loader={loading}
                    type={"submit"}
                    onClick={(e) => onSubmitHandler(e)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
