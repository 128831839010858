import axios from "axios";
import { API_BASE_URL } from "../constants/configuration";

const defaultOptions = {
  baseURL: API_BASE_URL,
};

// axios instance for making requests
const axiosInstance = axios.create(defaultOptions);

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  if (localStorage.getItem("token")) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  } else {
    config.headers["Authorization"] = null;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response, dispatch) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
