import React, { useEffect, useState } from "react";
import "./blogs.scss";
import { Link, useParams } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { useDispatch, useSelector } from "react-redux";
import { blog_details, latest_blogs } from "../../redux/commonReducer";
import { getAllBlogs, getBlogDetails } from "../../redux/actionCreator";

export default function BlogDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const blogDetails = useSelector(blog_details);
  const latestBlog = useSelector(latest_blogs);

  useEffect(() => {
    dispatch(getBlogDetails(id));
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <section className="zlox__blogs blog__details">
      <div className="container max-w-screen-xl xs:mx-[0] xs:p-3 md:mx-auto md:p-10 h-[100%] mb-10">
        <div className="heading">
          <h1 className="ff-semibold md:text-2xl mb-4 mt-2">
            {blogDetails?.title}
          </h1>
        </div>
        <div className="details__image">
          <img
            className="w-100"
            src={IMAGE_BASE_URL + "blog/" + blogDetails?.blog_image}
            alt=""
          />
        </div>
        <div dangerouslySetInnerHTML={{ __html: blogDetails?.content }}></div>

        <h3>Latest Blogs</h3>
        <div className="grid grid-cols-12 gap-6">
          {latestBlog?.map((ele, i) => {
            return (
              <div
                class="Blog__grid md:col-span-6 xs:col-span-12"
                key={i}
                data-aos="fade-up"
                data-aos-duration={`${i + 1}000`}
              >
                <div className="image">
                  <img
                    className="main"
                    src={IMAGE_BASE_URL + "blog/" + ele?.blog_image}
                    alt=""
                  />
                </div>
                <h2>{ele?.title}</h2>
                <p>{ele?.desc}</p>
                <Link to={`/blogs/blog-details/${ele?._id}`}>
                  READ MORE{" "}
                  <img src="/assets/icons/arrow-up-short.svg" alt="" />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
